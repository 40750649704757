import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AuthService from "../service/AuthService";
import { useHistory } from "react-router-dom";
import LocalStorageService from '../service/LocalStorageService';
const localStorageService = LocalStorageService.getService();

export const Login = (props) => {
	const history = useHistory();

	const [initialValues,] = useState({ tckn: '', password: '' })
	const [errMessage, setErrMessage] = useState([]);
	return (
		<div className="login-body">
			<div className="login-image">
				<img src={`assets/layout/images/pages/login-${props.colorScheme === 'light' ? 'ondark' : 'onlight'}.png`} alt="atlantis" />
			</div>
			<div className="login-panel p-fluid">
				<div className="flex flex-column">
					<div className="flex align-items-center mb-6 logo-container">
						<img src={`assets/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="login-logo" alt="login-logo" />
						<img src={`assets/layout/images/appname-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="login-appname" alt="login-appname" />
					</div>
					<Formik
						enableReinitialize
						initialValues={initialValues}
						validationSchema={Yup.object().shape({
							tckn: Yup.string().required("TC Kimlik numarası boş bırakılamaz.").min(1, "TC Kimlik numarası en az 11 karakter olmalıdır."),
							password: Yup.string().required("Şifre alanı boş bırakılamaz.").min(1, "Şifreniz en az 6 karakter olmalıdır."),
						})}
						onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
							setSubmitting(true);
							try {
								const authService = new AuthService();
								authService.login(values)
									.then(function (res) {
										if (res.success) {
											if (res.data.usertype != 9)
												setErrMessage(['Sadece yöneticiler bu alana giriş yapabilir.'])
											else {
												localStorageService.setUser(res.data);
												history.replace('/#/');
											}
										} else
											setErrMessage(res.messages)
									})
									.catch(function (error) {
										if (error.response) {
											// Request made and server responded
											console.log(error.response.data);
											console.log(error.response.status);
											console.log(error.response.headers);
										} else if (error.request) {
											// The request was made but no response was received
											console.log(error.request);
										} else {
											// Something happened in setting up the request that triggered an Error
											console.log('Error', error.message);
										}
									});
							} catch (err) {
								console.log("err", err);
							}
							setSubmitting(false);
						}}
					>
						{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
							<form noValidate onSubmit={handleSubmit}>
								<div className="form-container">

									<span className="p-input-icon-left">
										<i className="pi pi-envelope"></i>
										<InputText type="text"
											placeholder="TC Kimlik Numarası"
											error={touched.tckn && errors.tckn}
											name="tckn"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.tckn} />

									</span>
									<span className="p-input-icon-left">
										<i className="pi pi-key"></i>
										<InputText type="password"
											placeholder="Şifre"
											error={touched.password && errors.password}
											name="password"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.password} />
									</span>
								</div>
								<div className="button-container">
									<Button type="submit" disabled={isSubmitting} label="Giriş"></Button>
								</div>
								<div className="p-d-flex p-flex-column text-left">
									<div>{touched.tckn && errors.tckn && <small style={{ color: 'red' }}>{errors.tckn}</small>}</div>
									<div>{touched.password && errors.password && <small style={{ color: 'red' }}>{errors.password}</small>}</div>
									{errMessage.length > 0 && errMessage.map((err, index) => <div key={index}><small style={{ color: 'red' }}>{err}</small></div>)}

								</div>
							</form>
						)}
					</Formik>
				</div>

				<div className="login-footer flex align-items-center">
					<div className="flex align-items-center login-footer-logo-container">
						<img src="assets/layout/images/logo-gray.png" className="login-footer-logo" alt="login-footer-logo" />
						<img src="assets/layout/images/appname-gray.png" className="login-footer-appname" alt="login-footer-appname" />
					</div>
					<span>Copyright {new Date().getFullYear()}</span>
				</div>
			</div>
		</div>
	)
}