import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import OrderService from '../service/OrderService';
import CodelookupService from '../service/CodelookupService';
import { MultiSelect } from 'primereact/multiselect';
import { Sidebar } from 'primereact/sidebar';
import { Dropdown } from 'primereact/dropdown';
import OrderDetailsComponent from './OrderDetailsComponent';

const Orders = () => {

    let emptyOrder = {
        idProducts: null,
        idProductCategories: null,
        name: '',
        description: '',
        price: 0,
        status: 0
    };

    const [orders, setOrders] = useState(null);
    const [orderID, setOrderID] = useState(null);
    const [codelookupStatus, setCodelookupStatus] = useState([]);

    const [orderDialog, setOrderDialog] = useState(false);
    const [deleteOrderDialog, setDeleteOrderDialog] = useState(false);
    const [deleteOrdersDialog, setDeleteOrdersDialog] = useState(false);
    const [order, setOrder] = useState(emptyOrder);
    const [selectedOrders, setSelectedOrders] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [loading, setLoading] = useState(false);
    const [visibleRight, setVisibleRight] = useState(false);
    const [statusFilterOption, setStatusFilterOption] = useState(null);
    const [updateStatusOption, setUpdateStatusOption] = useState(null);


    const toast = useRef(null);
    const dt = useRef(null);


    const loadOrders = async (data) => {
        setLoading(true);
        const orderService = new OrderService();
        await orderService.getOrderList(data)
            .then(d => setOrders(d.data))
            .finally(() => setLoading(false));
    }

    const updateOrderStatus = async (data) => {
        setLoading(true);
        const orderService = new OrderService();
        let res;
        await orderService.updateOrderStatus(data)
            .then(d => res = d)
            .finally(() => setLoading(false));
        return await res;
    }

    const loadCodelookupStatus = async (data) => {
        const codelookupService = new CodelookupService();
        await codelookupService.getLookupList(data)
            .then(d => setCodelookupStatus(d.data));
    }

    useEffect(() => {
        loadOrders({
            "searchValue": "",
            "orderStatus": []
        });
        loadCodelookupStatus({
            "name": "orderstatus"
        });
        return () => {
            setOrders([])
            setCodelookupStatus([])
        }
    }, []);

    const formatDate = (value) => {
        return new Date(value).toLocaleDateString('tr', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        });
    }

    const hideDialog = () => {
        setSubmitted(false);
        setOrderDialog(false);
    }

    const hideDeleteOrderDialog = () => {
        setDeleteOrderDialog(false);
    }

    const hideDeleteOrdersDialog = () => {
        setDeleteOrdersDialog(false);
    }

    const saveOrder = async () => {

    }

    const editOrder = (order) => {
        setOrder({ ...order });
        setVisibleRight(true)
    }

    const confirmDeleteOrder = (order) => {
        setOrder(order);
        setDeleteOrderDialog(true);
    }

    const deleteOrder = async () => {

    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteOrdersDialog(true);
    }

    const deleteSelectedOrders = async () => {
        selectedOrders.map(async (d) => {
            const res = await updateOrderStatus({
                "idUserOrders": d.idUserOrders,
                "idcodelookup": updateStatusOption,
                "message": ""
            })

            if (res.success)
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Seçilen siparişin durumu başarıyla düzenlendi.', life: 3000 });
            else
                toast.current.show({ severity: 'error', summary: 'Hata', detail: res.messages.join('\n'), life: 3000, style: { 'whiteSpace': 'pre-line' } });
        })
        await loadOrders({
            "searchValue": "",
            "orderStatus": []
        })

        setDeleteOrdersDialog(false);
        setSelectedOrders(null);


    }

    const onCategoryChange = (e) => {
        let _product = { ...order };
        _product['idProductCategories'] = e.value;
        setOrder(_product);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...order };
        _product[`${name}`] = val;

        setOrder(_product);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _product = { ...order };
        _product[`${name}`] = val;

        setOrder(_product);
    }

    const leftToolbarTemplate = () => {
        let arr = [{ name: "Tüm Siparişleri Göster", value: [] }];
        codelookupStatus.map((d) => arr.push(
            { name: d?.value, value: [d?.idcodelookup] }
        ))
        return (
            <React.Fragment>
                <Dropdown
                    className='ml-2'
                    value={statusFilterOption}
                    onChange={(e) => {
                        loadOrders({ "searchValue": "", "orderStatus": e.value })
                        setStatusFilterOption(e.value)
                    }}
                    options={arr}
                    optionLabel="name"
                    placeholder="Tüm Siparişleri Göster" />

            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        let arr = [];
        codelookupStatus.map((d) => arr.push(
            { name: d?.value, value: d?.idcodelookup }
        ))
        return (
            <React.Fragment>
                <Dropdown
                    className='mr-2'
                    value={updateStatusOption}
                    onChange={(e) => {
                        setUpdateStatusOption(e.value)
                    }}
                    options={arr}
                    optionLabel="name"
                    placeholder="Seçim Yapınız" />
                <Button label="Seçilenlerin Durumunu Değiştir" icon="pi pi-pencil" className="p-button-primary" onClick={confirmDeleteSelected} disabled={!selectedOrders || !selectedOrders.length} />
            </React.Fragment>
        )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.idUserOrders}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">İsim</span>
                <strong>{rowData.userDTO.nameSurname}</strong><br />
                {rowData.userDTO?.mobilePhone}<br />
                {rowData.userAddressDTO?.acikAdres}<br />
                {rowData.userAddressDTO?.mahalleTxt}<br />
                {rowData.userAddressDTO?.semtTxt} / {rowData.userAddressDTO?.ilceTxt} / {rowData.userAddressDTO?.sehirTxt}
            </>
        );
    }

    const productBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Ürün</span>
                {rowData.productDTO.name}<br />
                <strong>{rowData.price} Para Puan</strong>
            </>
        )
    }

    const statusHistoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Durum Hareketleri</span>
                {rowData.userOrderHistoryDTOs.map((d, index) =>
                    <span key={index}>{d.orderStatusTxt} - {formatDate(d.createdDate)}<br /></span>
                )}
            </>
        );
    }

    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Kategori</span>
                {rowData.productCategoryTxt}
            </>
        );
    }

    const ratingBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reviews</span>
                <Rating value={rowData.rating} readonly cancel={false} />
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {
        const lastStatus = [].concat(rowData.userOrderHistoryDTOs);
        return (
            <>
                <span className="p-column-title">Son Durum</span>
                {lastStatus.sort(e => new Date(e.createdDate)).reverse()[0].orderStatusTxt}
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editOrder(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Siparişleri Yönet</h5>
            <div className="flex flex-row ">
                <span className="block mt-2 md:mt-0 p-input-icon-left mr-2">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
                <Button label="Excel Aktar" icon="pi pi-upload" className="p-button-raised p-button-secondary" onClick={exportCSV} />
            </div>
        </div>
    );

    const orderDialogFooter = (
        <>
            <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={saveOrder} />
        </>
    );
    const deleteOrderDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteOrdersDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteOrder} />
        </>
    );
    const deleteOrdersDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteOrdersDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedOrders} />
        </>
    );

    const representativesItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }} className="image-text">{option.name}</span>
            </div>
        );
    }
    const representativeFilterTemplate = (options) => {
        return (<>
            <div className="mb-3 text-bold">Duruma Göre Filtre</div>
            <MultiSelect value={options.value} options={[{ name: 'Aktif', value: 121 }, { name: 'Pasif', value: 118 }]} itemTemplate={representativesItemTemplate} onChange={(e) => options.filterCallback(e.value)} optionLabel="name" placeholder="Tümü" className="p-column-filter" />
        </>
        )
    }

    const rowDetails = (rowData) => {
        return (<Button className="p-button-raised p-button-secondary mr-2 mb-2" label="Detay" icon="pi pi-chevron-right" onClick={() => { setVisibleRight(true); setOrderID(rowData.idUserOrders); }} />)
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <DataTable loading={loading} ref={dt} stripedRows showGridlines value={orders} selection={selectedOrders} onSelectionChange={(e) => setSelectedOrders(e.value)}
                        dataKey="idUserOrders" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{totalRecords} toplam kayıttan, {first} ile {last} arasındaki kayıtlar gösteriliyor. "
                        globalFilter={globalFilter} emptyMessage="Veri bulunamadı." header={header} responsiveLayout="scroll"
                        sortField="idUserOrders" sortOrder={-1}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="idUserOrders" header="ID" sortable body={codeBodyTemplate} headerStyle={{ width: '4%', minWidth: '4rem' }}></Column>
                        <Column field="userDTO.nameSurname" header="Sipariş Veren" body={nameBodyTemplate} headerStyle={{}}></Column>
                        <Column field="productDTO.name" header="Ürün" body={productBodyTemplate} headerStyle={{ width: '24%', minWidth: '25rem', }} bodyStyle={{}}></Column>
                        <Column field="userOrderHistoryDTOs.orderStatusTxt" header="Durum Hareketleri" body={statusHistoryBodyTemplate} headerStyle={{ width: '18%', minWidth: '20rem' }}></Column>
                        <Column header="Son Durum" filterField="status" body={statusBodyTemplate} headerStyle={{ width: '8%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        <Column header="Detay" body={rowDetails} headerStyle={{ width: '8%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                    </DataTable>

                    <Sidebar visible={visibleRight} onHide={() => setVisibleRight(false)} style={{ width: '450px' }} baseZIndex={1000} position="right">
                        <div className="card p-fluid">
                            <h5>Bilgi</h5>
                            <div>Siparişle ilgili sadece durum değişikliği yapabilirsiniz.</div>
                        </div>
                        <OrderDetailsComponent orderID={orderID} />


                        <div className='flex justify-content-between'>
                            <Button className="p-button-raised p-button-secondary mr-2 mb-2" label="Kapat" icon="pi pi-times" onClick={() => setVisibleRight(false)} />
                        </div>
                    </Sidebar>

                    <Dialog visible={orderDialog} style={{ width: '450px' }} header="Ürün Bilgileri" modal className="p-fluid" footer={orderDialogFooter} onHide={hideDialog}>
                        {order.image && <img src={`${order.image}`} alt={order.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                        <div className="field">
                            <label htmlFor="name">İsim</label>
                            <InputText id="name" value={order.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !order.name })} />
                            {submitted && !order.name && <small className="p-invalid">Name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="description">Kısa Açıklama</label>
                            <InputTextarea id="description" value={order.description} onChange={(e) => onInputChange(e, 'description')} required rows={5} cols={20} />
                        </div>

                        <div className="field">
                            <label className="mb-3">Kategori</label>
                            <div className="formgrid grid">
                                {codelookupStatus.map((category, index) =>
                                    <div className="field-radiobutton col-6" key={index}>
                                        <RadioButton inputId={category.idProductCategories} name="category" value={category.value} onChange={onCategoryChange} checked={order.idProductCategories === category.idProductCategories} />
                                        <label htmlFor="category1">{category.value}</label>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="price">Para Puan</label>
                                <InputNumber id="price" value={order.price} onValueChange={(e) => onInputNumberChange(e, 'price')} mode="decimal" locale="tr" />
                            </div>
                            <div className="field col">
                                <label htmlFor="quantity">Stok</label>
                                <InputNumber id="quantity" value={order.quantity} onValueChange={(e) => onInputNumberChange(e, 'quantity')} integeronly />
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteOrderDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteOrderDialogFooter} onHide={hideDeleteOrderDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {order && <span><b>{order.name}</b> isimli ürünü pasif yapmak istediğinize emin misiniz?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteOrdersDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteOrdersDialogFooter} onHide={hideDeleteOrdersDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {order && <span>Seçili olan siparişlerin durumunu değiştirmek istediğinize emin misiniz?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Orders, comparisonFn);