import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';

export const Access = (props) => {
	const history = useHistory();

	const goDashboard = () => {
		history.push('/');
	}

	return (
		<div className="exception-body accessdenied">
			<div className="exception-panel">
				<h1>HATA</h1>
				<h3>Erişim Reddi!</h3>
				<p>Bu sayfaya erişmek için yetkiniz bulunmamaktadır.</p>
				<Button type="button" label="Ana Sayfaya Dön" onClick={goDashboard}></Button>
			</div>
			<div className="exception-footer">
				<img src={`assets/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="exception-logo" alt="exception-logo" />
				<img src={`assets/layout/images/appname-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="exception-appname" alt="exception-appname" />
			</div>
		</div>
	)
}