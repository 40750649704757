import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import OrderService from '../service/OrderService';

const OrderDetailsComponent = ({orderID}) => {

    const [orderDetails, setOrderDetails] = useState(null);

    useEffect(() => {
        getOrder(orderID)
    },[orderID])

    const getOrder = async (data) => {
        const orderService = new OrderService();
        await orderService.getOrder(data)
            .then(d => setOrderDetails(d.data));
    }

    const formatDate = (value) => {
        return new Date(value).toLocaleDateString('tr', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        });
    }

    const customDateBody = (rowData) => {
        return (
            <span>{formatDate(rowData.createdDate)}</span>
        );
    }

    return (
        orderDetails != null && <><div className="card p-fluid">
        <h6>Sipariş Veren</h6>
        <div>
            <strong>{orderDetails.userDTO?.nameSurname}</strong><br />
            {orderDetails.userDTO?.mobilePhone}<br />
            {orderDetails.userAddressDTO?.acikAdres}<br />
            {orderDetails.userAddressDTO?.mahalleTxt}<br />
            {orderDetails.userAddressDTO?.semtTxt} / {orderDetails.userAddressDTO?.ilceTxt} / {orderDetails.userAddressDTO?.sehirTxt}
        </div>
    </div>
    <div className="card p-fluid">
        <h6>Ürün</h6>
        <div>
            {orderDetails.productDTO?.name}<br />
            <strong>{orderDetails?.price} Para Puan</strong>
        </div>
    </div>
    <div className="card p-fluid">
        <h6>Sipariş Hareketleri</h6>
        <div>
            <DataTable value={orderDetails.userOrderHistoryDTOs}>
                <Column field="orderStatusTxt" header="Durum" />
                <Column header="Tarih" body={customDateBody} />
            </DataTable>
        </div>
    </div></>
    );
}


export default OrderDetailsComponent;