import axiosBase from './AxiosBase';

export default class OrderService {
    async getOrderList(data) {
        return axiosBase.post('Order/OrderList', data).then(res => res.data);
    }
    async getOrder(data) {
        return axiosBase.get('Order/Get/' + data).then(res => res.data);
    }
    async updateOrderStatus(data) {
        return axiosBase.post('Order/Update/Status', data).then(res => res.data);
    }
}
