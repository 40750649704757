const LocalStorageService = (function () {
    var _service;
    function _getService() {
        if (!_service) {
            _service = this;
            return _service
        }
        return _service
    }
    function _setUser(tokenObj) {
        localStorage.setItem('access_token', tokenObj.token);
        localStorage.setItem('user', JSON.stringify(tokenObj));
    }

    function _getAccessToken() {
        return localStorage.getItem('access_token');
    }

    function _clearUser() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
    }
    function _getUser() {
        return JSON.parse(localStorage.getItem('user')) ;
    }

    return {
        getService: _getService,
        getaccesstoken: _getAccessToken,
        setUser: _setUser,
        clearUser: _clearUser,
        getUser: _getUser
    }
})();
export default LocalStorageService;