import axiosBase from './AxiosBase';

export default class DashboardService {
    async getUserPointSummary(data) {
        return axiosBase.post('Dashboard/UserPointSummary', data).then(res => res.data);
    }

    async getPointMonthlyPercentChange(data) {
        return axiosBase.post('Dashboard/PointMonthlyPercentChange', data).then(res => res.data);
    }

    async getMonthlyPointSummary(data) {
        return axiosBase.post('Dashboard/MonthlyPointSummary/' + data).then(res => res.data);
    }

    async getYearlySummary(data) {
        return axiosBase.post('Dashboard/YearlySummary/' + data).then(res => res.data);
    }

    async getTopProductPoint(period, count, userid = 0) {
        let url = 'Dashboard/TopProductPoint/' + period + '/' + count;
        if(userid != 0)
            url = url  + '/?userid=' + userid
        return axiosBase.post(url).then(res => res.data);
    }

    async getTopPointEarnedUser(period, count) {
        return axiosBase.post('Dashboard/TopPointEarnedUser/' + period + '/' + count).then(res => res.data);
    }

    async getLastReadBarcode(count, userid = 0) {
        let url = 'Dashboard/LastReadBarcode/' + count;
        if(userid != 0)
            url = url  + '/?userid=' + userid
        return axiosBase.post(url).then(res => res.data);
    }

    async getBarcodeStatus() {
        return axiosBase.post('Dashboard/BarcodeStatus').then(res => res.data);
    }

    async getDailyUserCount(startdate) {
        return axiosBase.post('Dashboard/DailyUserCount?startdate=' + startdate).then(res => res.data);
    }

    async getTopPointAmountUsedUser(count, type) {
        return axiosBase.post('Dashboard/TopPointAmountUsedUser/' + count + '/' + type).then(res => res.data);
    }
}
