import axiosBase from './AxiosBase';

export default class UserService {
    async getUserList(data) {
        return axiosBase.post('User/Dashboard/List', data).then(res => res.data);
    }
    async getUserSummary(data) {
        return axiosBase.post('User/Dashboard/Summary?userid=' + data).then(res => res.data);
    }
}
