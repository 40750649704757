import axios from 'axios';
import LocalStorageService from "./LocalStorageService";
const localStorageService = LocalStorageService.getService();

const URL = 'https://albera.softsis.com.tr/';

const axiosBase = axios.create({
    baseURL: URL,
});

axiosBase.interceptors.response.use(response => {
    return response;
}, error => {
    if(error.response.status === 401){
        window.location.href = "#/login"
        localStorageService.clearUser()
    }
    return Promise.reject(error);
});

axiosBase.interceptors.request.use(
    async (config) => {
        const token = localStorageService.getaccesstoken();
        if (token)
            config.headers.Authorization = 'Bearer ' + token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axiosBase.defaults.params = {};

export default axiosBase;