
import axiosBase from './AxiosBase';

export default class ProductService {
    async getProductList(data) {
        return axiosBase.post('Product/Dashboard/List', data).then(res => res.data);
    }
    async getProductCategoryList(data) {
        return axiosBase.post('Product/CategoryList', data).then(res => res.data);
    }
    async updateProduct(data) {
        return axiosBase.post('Product/Update', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => res.data);
    }
    async updatePassiveProducts(data) {
        return axiosBase.post('Product/Update/Passive', data).then(res => res.data);
    }
    async createProduct(data) {
        return axiosBase.post('Product/Create', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => res.data);
        //return axiosBase.post('Product/Create', data).then(res => res.data);
    }

    getProductsSmall() {
        return axiosBase.get('assets/demo/data/products-small.json').then((res) => res.data.data);
    }

    getProducts() {
        return axiosBase.get('assets/demo/data/products.json').then((res) => res.data.data);
    }

    getProductsMixed() {
        return axiosBase.get('assets/demo/data/products-mixed.json').then((res) => res.data.data);
    }

    getProductsWithOrdersSmall() {
        return axiosBase.get('assets/demo/data/products-orders-small.json').then((res) => res.data.data);
    }
}
